import React, { FC, useCallback, useMemo } from 'react'
import { ThemeProvider } from 'next-themes'

export interface State {
  displaySidebar: boolean
  displayDropdown: boolean
  displayModal: boolean
  sidebarView: string
  modalView: string
  userAvatar: string
  isSnapping: boolean
  displayNav: boolean
  displayFooter: boolean
  pageTheme: string
  pageTransitioning: boolean
  containerFixedWidth: boolean
}

const initialState = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: '',
  isSnapping: false,
  displayNav: true,
  displayFooter: true,
  pageTheme: 'DARK',
  pageTransitioning: false,
  containerFixedWidth: false,
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'SET_SNAPPING_ON'
    }
  | {
      type: 'SET_SNAPPING_OFF'
    }
  | {
      type: 'OPEN_NAV'
    }
  | {
      type: 'CLOSE_NAV'
    }
  | {
      type: 'SHOW_FOOTER'
    }
  | {
      type: 'HIDE_FOOTER'
    }
  | {
      type: 'SET_PAGE_THEME'
      value: THEMES
    }
  | {
      type: 'START_PAGE_TRANSITION'
    }
  | {
      type: 'END_PAGE_TRANSITION'
    }
  | {
      type: 'SET_CONTAINER_FIXED_WIDTH'
    }
  | {
      type: 'SET_CONTAINER_FULL_WIDTH'
    }

type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'

type SIDEBAR_VIEWS =
  | 'CART_VIEW'
  | 'CHECKOUT_VIEW'
  | 'PAYMENT_METHOD_VIEW'
  | 'SET_FITTER_VIEW'

type THEMES = 'DARK' | 'LIGHT'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'SET_SNAPPING_ON': {
      return {
        ...state,
        isSnapping: true,
      }
    }
    case 'SET_SNAPPING_OFF': {
      return {
        ...state,
        isSnapping: false,
      }
    }
    case 'OPEN_NAV': {
      return {
        ...state,
        displayNav: true,
      }
    }
    case 'CLOSE_NAV': {
      return {
        ...state,
        displayNav: false,
      }
    }
    case 'SHOW_FOOTER': {
      return {
        ...state,
        displayFooter: true,
      }
    }
    case 'HIDE_FOOTER': {
      return {
        ...state,
        displayFooter: false,
      }
    }
    case 'SET_PAGE_THEME': {
      return {
        ...state,
        pageTheme: action.value,
      }
    }
    case 'START_PAGE_TRANSITION': {
      return {
        ...state,
        pageTransitioning: true,
      }
    }
    case 'END_PAGE_TRANSITION': {
      return {
        ...state,
        pageTransitioning: false,
      }
    }
    case 'SET_CONTAINER_FIXED_WIDTH': {
      return {
        ...state,
        containerFixedWidth: true,
      }
    }
    case 'SET_CONTAINER_FULL_WIDTH': {
      return {
        ...state,
        containerFixedWidth: false,
      }
    }
  }
}

export const UIProvider: FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openSidebar = useCallback(
    () => dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch]
  )
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )

  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }),
    [dispatch]
  )

  const setSnappingOn = useCallback(() => {
    document.documentElement.classList.add('is-snapping')
    return dispatch({ type: 'SET_SNAPPING_ON' })
  }, [dispatch])

  const setSnappingOff = useCallback(() => {
    document.documentElement.classList.remove('is-snapping')
    return dispatch({ type: 'SET_SNAPPING_OFF' })
  }, [dispatch])

  const openNav = useCallback(() => dispatch({ type: 'OPEN_NAV' }), [dispatch])
  const closeNav = useCallback(
    () => dispatch({ type: 'CLOSE_NAV' }),
    [dispatch]
  )

  const showFooter = useCallback(
    () => dispatch({ type: 'SHOW_FOOTER' }),
    [dispatch]
  )
  const hideFooter = useCallback(
    () => dispatch({ type: 'HIDE_FOOTER' }),
    [dispatch]
  )

  const setPageTheme = useCallback(
    (value: THEMES) => dispatch({ type: 'SET_PAGE_THEME', value }),
    [dispatch]
  )

  const startPageTransition = useCallback(
    () => dispatch({ type: 'START_PAGE_TRANSITION' }),
    [dispatch]
  )
  const endPageTransition = useCallback(
    () => dispatch({ type: 'END_PAGE_TRANSITION' }),
    [dispatch]
  )

  const setContainerFixedWidth = useCallback(
    () => dispatch({ type: 'SET_CONTAINER_FIXED_WIDTH' }),
    [dispatch]
  )

  const setContainerFullWidth = useCallback(
    () => dispatch({ type: 'SET_CONTAINER_FULL_WIDTH' }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setUserAvatar,
      setSnappingOn,
      setSnappingOff,
      openNav,
      closeNav,
      showFooter,
      hideFooter,
      setPageTheme,
      startPageTransition,
      endPageTransition,
      setContainerFixedWidth,
      setContainerFullWidth,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
