import React, { useContext } from 'react'

import { AppProps } from 'next/app'
import { useRouter } from 'next/router'

// import { useRouterScroll } from '@moxy/next-router-scroll';
// import { isHistoryEntryFromPopState } from '@moxy/react-page-swapper'
import PageSwapper from '@moxy/react-page-swapper'

// import {
//   PageTransition,

// } from '@components/common'

import { PageTransition, PageTransitionProps } from './PageTransition/PageTransition'

import style from './AppInner.module.scss'

const AppInner = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  const getNodeKey = (path: string) => {
    return path.split('?')[0]
  }

  const getAnimation = () => {
    return 'fade'
  }

  const getMode = () => {
    return 'out-in'
  }


  const getUpdateScroll = () => {
    // if you want to restore the scroll position for a specific page ...
    // if (isHistoryEntryFromPopState() && ['/projects'].includes(nodeKey)) {
    //   return updateScroll(prevContext, content);
    // } else {

    window.scrollTo(0, 0)
  }

  return (
    <PageSwapper
      className={`${style.pageSwapper} js-page-swapper`}
      updateScroll={getUpdateScroll}
      node={<Component {...pageProps} />}
      nodeKey={getNodeKey(router.asPath)}
      animation={getAnimation}
      mode={getMode}
    >
      {(props: PageTransitionProps) => <PageTransition {...props} />}
    </PageSwapper>
  )
}


export default AppInner