import React, { cloneElement, ReactElement, useEffect, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
// import { getCSSVar, setCSSVar } from 'utils/helpers';
import styles from './PageTransition.module.scss'
// import { useAppContext } from 'context/AppContext'
import { useUI } from '@components/ui/context'
// import { DEBUG } from 'siteConfig'

/* try not to add any context here : add it in AppInner and pass it through 
   in either *mode* *animation* *inProp*, then define which transitions to use
    based on those variables */

export type PageTransitionProps = {
  node: ReactElement
  animation: string
  mode: string
  transitioning: boolean
  style: object
  in: boolean
  onEntered: () => void
  onExited: () => void
}

export type CloneProps = {
  type: string
  transitioning: boolean
}

export const PageTransition = ({
  node,
  animation,
  mode,
  transitioning = false,
  style,
  in: inProp = false,
  onEntered,
  onExited,
}: PageTransitionProps) => {
  const { pageTransitioning, startPageTransition, endPageTransition } =
    useUI()

  const PAGE_TRANSITION_DEBUG = false
  // PAGE_TRANSITION_DEBUG &&
  //   console.log(node.props.meta_data.meta_title) &&
  //   console.log(
  //     `${mode} ${inProp ? 'new' : 'old'} ${animation} ${
  //       transitioning ? 'transitioning' : ''
  //     }`
  //   )

  const getTimeout = (animation: string) => {
    /* IMPORTANT! this must match css transitions */
    switch (animation) {
      case 'fade':
        return 650
      default:
        return 0
    }
  }

  // TODO this stuff feels like it should be in AppInner.?
  const handleComplete = () => {
    /* NOTE the timing of this isn't really stable, depending on if the animation is 
            out-in or simultaneous, there may be some additional logic with simultaneous
            depending on if one animation was longer than the other.
            
        TODO consider using onSwapEnd on PageSwapper */
    endPageTransition()

    PAGE_TRANSITION_DEBUG && console.log('complete')
    // setShowMenu(undefined)
  }
  const handleEnter = () => {
    PAGE_TRANSITION_DEBUG && console.log('enter')
  }

  const handleEntered = () => {
    PAGE_TRANSITION_DEBUG && console.log('entered')

    if (['fade', 'menu', 'pop'].includes(animation)) handleComplete()
    onEntered()
  }
  const handleExit = () => {
    PAGE_TRANSITION_DEBUG && console.log('exit')
    startPageTransition()
  }
  const handleExited = () => {
    PAGE_TRANSITION_DEBUG && console.log('exited')

    // if ([].includes(animation)) handleComplete(e);
    onExited()
  }

  const handleEnd = (node: any, done: any) => {
    PAGE_TRANSITION_DEBUG && console.log('end')
    
    // console.log('start')

    node.addEventListener('transitionend', done, false)
  }

  return (
    <CSSTransition
      in={inProp}
      onEntered={handleEntered}
      onExited={handleExited}
      onEnter={handleEnter}
      onExit={handleExit}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      timeout={getTimeout(animation)}
      addEndListener={handleEnd}
    >
      <div
        className={`${pageTransitioning ? styles.faded : ''} ${
          styles[animation]
        }`}
        style={{ ...style }}
      >
        {cloneElement(node, {
          pageTransition: {
            type: inProp ? 'enter' : 'exit',
            transitioning: transitioning,
          },
        })}
      </div>
    </CSSTransition>
  )
}

// export default PageTransition